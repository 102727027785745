



















import Vue from 'vue'
import { HFooter } from '@happytal/bo-ui-library'

export default Vue.extend({
    components: {
        HFooter
    }
})
