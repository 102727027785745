<template>
    <div class="home">
        <div class="content">
            <div class="logo">
                <img class="image" src="@/assets/images/ehpad/logo_happydom.png" />
            </div>
            <div class="slogan">
                Bienvenue sur la plateforme de coordination pour
                le bien vieillir en EHPAD, USLD et Résidence
                Autonomie.
            </div>
            <div class="home-box">
                <HRow>
                    <HCol :cols="6" v-if="$breakpoint.mdAndUp">
                        <div class="home-splash">
                            <div class="home-howto">
                                Comment me connecter ?
                            </div>
                            <a href="https://www.youtube.com/watch?v=9LrGiNY0DUI&t=6s&ab_channel=happytal" target="blank">
                                <img height="320" width="90%" src="@/assets/images/ehpad/thumbnail-youtube.png"/>
                            </a>
                        </div>
                    </HCol>
                    <HCol :cols="$breakpoint.mdAndUp ? 6 : 12">
                        <div class="home-form">
                            <div class="home-legend">
                                Connectez-vous à votre espace happydom pour
                                échanger avec les équipes de l'ehpad, gérer
                                les besoins de votre proche et avoir réponse
                                à toutes vos questions.
                            </div>
                            <div class="text">J'ai un compte</div>
                            <HBtn
                                class="login-btn"
                                to="/connexion"
                            >Me connecter</HBtn>
                            <div class="text">Je n'ai pas encore activé mon compte</div>
                            <HBtn
                                class="account-btn"
                                style-type="secondary"
                                to="/forgot-password"
                            >Activer mon compte</HBtn>
                            <div class="links">
                                <a
                                    class="plus-link"
                                    href="https://pro.happytal.com/conciergerie-service-resident/"
                                    target="_blank"
                                >Vous êtes professionnel ? En savoir plus</a>
                            </div>
                        </div>
                    </HCol>
                </HRow>
            </div>
            <TheFooter />
        </div>
    </div>
</template>

<script>
    import Oauth from '@/oauth'
    import Routes from '@/constants/routes'

    import {
        HRow,
        HCol,
        HBtn
    } from '@happytal/bo-ui-library'

    import TheFooter from '@/components/TheFooter'

    export default {

        created () {
            this.checkToRedirect()
        },

        updated () {
            this.checkToRedirect()
        },

        components: {
            HRow,
            HCol,
            HBtn,
            TheFooter
        },

        methods: {
          async checkToRedirect () {
            if (!Oauth.isLoggedIn()) {
                return
            }
            const userId = this.$store.getters['dataUser/getUserId']()
            const institutionId = this.$store.getters['dataUser/getInstitutionId']()
            if (!this.$store.state.dataResidents.isRegistered) {
                await this.$store.dispatch('dataResidents/getResidents', { userId, institutionId })
            }
            const residentsNumber = this.$store.getters['dataResidents/residentsNumber']
            if (residentsNumber === 1) {
                this.$router.push({
                    name: Routes.ResidentDashboard,
                    params: {
                        resident_id: this.$store.state.dataResidents.residents[0].id
                    }
                })
                return
            }
            this.$router.push({
                name: Routes.Residents
            })
          }
        }
    }
</script>

<style lang="scss" scoped>
    .home {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100%;
        color: #2c2c2c;

        @media (max-width: 600px) {
            display: block;
        }

        .content {
            max-width: 1000px;
            text-align: center;
        }
        .logo {
            height: 80px;

            @media (max-width: 600px) {
                margin: 20px 0px 0px 0px;
            }

            .image {
                height: 100%;
                object-fit: contain;
                padding: 0px 30px 0px 30px;

                @media (max-width: 600px) {
                    max-width: 290px !important;
                }
            }
        }

        .slogan {
            margin: 30px 20px 0px 20px;
            font-weight: 500;
            font-size: 25px;

            @media (max-width: 600px) {
                font-size: 16px;
            }
        }
        .home-box {
            margin: 50px 0px 50px 0px;
            background: #fff;
            border-radius: 6px;
            box-shadow: 0 4px 25px 0 rgba(0, 0, 0, .1);

            @media (max-width: 600px) {
                margin: 30px 0px 30px 0px;
                border-radius: 0px;
            }

            .home-splash {
                padding: 30px 0px 30px 0px;

                .home-howto {

                }
                .home-video {
                    margin: 15px 15px 0px 15px;
                }
            }
            .home-form {
                padding: 30px 30px 30px 30px;

                .home-legend {

                }
                .text {
                    margin: 30px 0px 10px 0px;
                    font-weight: 600;
                }
                .login-btn {
                    width: 100%;
                }
                .account-btn {
                    width: 100%;
                }
                .links {
                    margin: 35px 0px 0px 0px;
                    text-align: right;

                    @media (max-width: 600px) {
                        text-align: center;
                    }

                    .plus-link {
                        font-size: 12px;
                        text-decoration: underline;
                        color: rgba(var(--vs-primary), 1);
                    }
                    .plus-link:visited {
                        font-size: 12px;
                        color: rgba(var(--vs-primary), 1);
                    }
                }
            }
        }
    }
</style>
